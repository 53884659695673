<template>
  <div>
    <!-- search and filters -->
    <div class="d-flex align-center" style="gap: 20px">
      <!-- search -->
      <div style="width: 220px">
        <v-text-field
          v-model="search"
          clearable
          outlined
          dense
          placeholder="Search name, email, or etc."
          prepend-inner-icon="mdi-magnify"
          color="primary"
          class="rounded-lg"
        ></v-text-field>
      </div>

      <!-- date filter -->
      <div style="width: 220px">
        <v-menu
          ref="dateMenu"
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          max-width="290px"
          min-width="auto"
          offset-y
          nudge-top="20"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-text-field
              :value="dateRangeText"
              placeholder="Filter by Date Range"
              v-bind="attrs"
              height="42px"
              outlined
              dense
              append-icon="mdi-calendar"
              v-on="on"
              clearable
              readonly
              class="rounded-lg"
              @click:clear="dateRange = []"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            v-model="dateRange"
            range
            no-title
            scrollable
            :max="maxDate"
            @input="onDateSelected"
          >
          </v-date-picker>
        </v-menu>
      </div>
    </div>

    <!-- tabs -->
    <v-sheet elevation="3" class="rounded-b-0">
      <v-tabs
        height="125px"
        next-icon="mdi-chevron-right-circle"
        prev-icon="mdi-chevron-left-circle"
        v-model="activeTab"
        show-arrows
      >
        <v-tab v-for="(item, id) in tabs" :key="id" class="tab-width">
          <div>
            <v-icon
              size="20"
              style="margin-top: -25px; font-size: 45px"
              :color="item.color"
            >
              mdi-circle-small
            </v-icon>

            <div class="tab-total" style="margin-top: -10px">
              {{ item.value }}
            </div>
            <div class="tab-name">
              {{ item.label }}
            </div>
          </div>
        </v-tab>
      </v-tabs>
    </v-sheet>

    <v-divider />

    <!-- dataTable -->
    <RefundDataTable ref="dataTable" @reloadData="getRefundCount" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import RefundDataTable from "@/views/app/refundManagement/refundTable";

export default {
  components: {
    RefundDataTable,
  },

  data() {
    return {
      dateMenu: false,
      tabs: [
        {
          label: `Pending`,
          key: "pending",
          value: 0,
          color: "#1149B7",
        },
        {
          label: `Successful`,
          key: "successful",
          value: 0,
          color: "#3DA922",
        },
        {
          label: `Cancelled`,
          key: "cancelled",
          value: 0,
          color: "red",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      getFilters: "refund/filters",
      getSearch: "refund/search",
      getActiveTab: "refund/activeTab",
    }),

    activeTab: {
      get() {
        return this.getActiveTab.index;
      },
      set(value) {
        this.setActiveTab({ index: value, key: this.tabs[value].key });
      },
    },

    maxDate() {
      const today = this.$moment();
      return today.format("YYYY-MM-DD");
    },

    search: {
      get() {
        return this.getSearch;
      },
      set(value) {
        this.setSearch(value);
      },
    },

    dateRange: {
      get() {
        return this.getFilters.date;
      },
      set(value) {
        this.setFilters({ date: value });
      },
    },

    dateRangeText() {
      const _filter_by_date = this.dateRange.map((item) =>
        this.$moment(item).format("DD-MM-YYYY")
      );
      return _filter_by_date.join(" ~ ");
    },
  },

  mounted() {
    this.getRefundCount();
    this.$root.$on("onNavClicked", this.navClickHandler);
  },

  beforeDestroy() {
    this.$root.$off("onNavClicked", this.navClickHandler);
  },

  methods: {
    ...mapActions({
      setSearch: "refund/setSearch",
      setFilters: "refund/setFilters",
      setActiveTab: "refund/setActiveTab",
    }),

    navClickHandler(navKey) {
      if (navKey === "refund_management") {
        this.reloadData();
      }
    },

    reloadData() {
      this.getRefundCount();
      this.$refs.dataTable.getRefundList();
    },

    onDateSelected() {
      if (this.dateRange.length === 2) this.dateMenu = false;
    },

    getRefundCount() {
      const onSuccess = (res) => {
        this.tabs.forEach((tab) => {
          tab.value = res.data.data[tab.key];
        });
        this.$forceCompute("tabs");
      };

      const params = {
        search: this.search,
      };

      if (this.dateRange) {
        if (this.dateRange.length === 2) {
          params["start_date"] = this.dateRange[0];
          params["end_date"] = this.dateRange[1];
        } else {
          params["date"] = this.dateRange[0];
        }
      }

      return this.$request(this.$keys.GET, this.$urls.case.refund.count, {
        params,
        onSuccess,
      });
    },
  },
};
</script>
<style scoped>
.tab-total {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
}
.tab-name {
  word-wrap: break-word;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
</style>
