<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    width="480px"
    content-class="rounded-xl"
  >
    <v-card :outlined="$vuetify.theme.dark" rounded="xl">
      <!-- dialog title -->
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-body-1">Approve Refund</div>
        <v-btn small icon @click="modalClosed">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <!-- dialog content -->
      <v-card-text>
        <v-form class="mt-6" lazy-validation ref="form">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            min-width="auto"
            offset-y
            nudge-top="20"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-text-field
                v-model="form.refund_date"
                label="Refund date"
                v-bind="attrs"
                :rules="[$rules.required]"
                solo
                outlined
                dense
                flat
                append-icon="mdi-calendar"
                v-on="on"
                readonly
                @click:clear="form.refund_date = null"
                class="rounded-lg"
              ></v-text-field>
            </template>
            <v-date-picker
              color="primary"
              v-model="form.refund_date"
              no-title
              :max="maxDate"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            v-model="form.transaction_id"
            :rules="[$rules.required]"
            outlined
            dense
            label="Transaction Id"
            class="rounded-lg"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-divider />

      <!-- dialog actions -->
      <v-card-actions class="py-3">
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          class="text-capitalize px-6 rounded-lg"
          :loading="btnLoading"
          @click="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      btnLoading: false,
      dateMenu: false,
      form: {
        refund_date: null,
        transaction_id: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      approveDialog: "refund/approveDialog",
    }),

    show: {
      get() {
        return this.approveDialog.show;
      },
      set(value) {
        this.toggleDialog({ show: value });
      },
    },

    maxDate() {
      const today = this.$moment();
      return today.format("YYYY-MM-DD");
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },
  methods: {
    ...mapActions({
      toggleDialog: "refund/toggleApproveDialog",
    }),

    openModal() {},

    modalClosed() {
      this.toggleDialog({ show: false });
      this.$refs.form.reset();
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;

        const onSuccess = () => {
          this.modalClosed();
          this.$emit("submitted");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        const data = {
          refund_id: this.approveDialog.id,
          ...this.form,
        };

        return this.$request(this.$keys.POST, this.$urls.case.refund.approve, {
          data,
          onSuccess,
          onFinally,
        });
      }
    },
  },
};
</script>
