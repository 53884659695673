var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-3 rounded-t-0",attrs:{"loading":_vm.loading,"loading-text":"Please wait ....Loading","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.status_datetime))+" ")]}},{key:"item.refund_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.refund_date))+" ")]}},{key:"item.case_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.case.case_id)+" ")]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.case.client.name)+" "),(item.case.client.email)?_c('div',[_vm._v(_vm._s(item.case.client.email))]):_vm._e(),(item.case.client.mobile)?_c('div',[_vm._v(" "+_vm._s(item.case.client.mobile_display)+" ")]):_vm._e()])]}},{key:"item.clause",fn:function(ref){
var item = ref.item;
return [(item.clause)?_c('div',[_vm._v(" "+_vm._s(item.clause.name)+" - "+_vm._s(item.clause.refund_percent)+"% ")]):_vm._e(),_vm._v(" Refund Percentage: "+_vm._s(item.refund_percent)+"% ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("University: ₹"+_vm._s(item.university_cost))]),_c('div',[_vm._v("Processing: ₹"+_vm._s(item.processing_cost))]),_c('div',[_vm._v("Shipping: ₹"+_vm._s(item.shipping_cost))]),_c('div',[_vm._v("Total: ₹"+_vm._s(item.total_refund_amount))])]}},{key:"item.remarks",fn:function(ref){
var item = ref.item;
return [(item.remarks)?_c('div',[_vm._v(" "+_vm._s(item.remarks)+" ")]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-capitalize font-weight-bold",attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status_display)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"gap":"10px"}},_vm._l((item.actions),function(action){return _c('v-btn',{key:("action-" + (action.key)),staticClass:"text-capitalize rounded-lg",attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.actionHandler(action.key, item)}}},[(action.icon)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-"+_vm._s(action.icon))]):_vm._e(),_vm._v(" "+_vm._s(action.text)+" ")],1)}),1)]}}],null,true)}),_c('v-container',{staticClass:"d-flex justify-space-between align-center px-0 mb-0 mt-4",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center text-subtitle-2"},[_c('div',{staticClass:"mr-2"},[_vm._v("Rows per page")]),_c('div',{staticStyle:{"width":"75px"}},[_c('v-select',{staticClass:"rounded-lg",attrs:{"menu-props":{ bottom: true, offsetY: true },"items":_vm.itemsPerPageList,"hide-details":"","dense":"","solo":"","color":"primary"},model:{value:(_vm.page_count),callback:function ($$v) {_vm.page_count=$$v},expression:"page_count"}})],1),_c('div',{staticClass:"ml-6"},[_vm._v(" Results: "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageEnd)+" of "+_vm._s(_vm.total_count)+" ")])]),_c('v-pagination',{attrs:{"length":_vm.page_total,"total-visible":5,"color":"primary"},model:{value:(_vm.page_number),callback:function ($$v) {_vm.page_number=$$v},expression:"page_number"}})],1),_c('ApproveDialog',{on:{"submitted":_vm.reloadData}}),_c('RejectDialog',{on:{"submitted":_vm.reloadData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }